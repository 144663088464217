import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const StyledButtonContainer = styled(ButtonContainer)`
  ${media.lessThan('lg')`
    margin-bottom: 100px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const FormulaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => get(theme, 'lightOrange')};
  margin: 32px auto 32px;
  text-align: center;
  width: 100%;
  max-width: 570px;

  ${media.greaterThan('sm')`
    margin: 40px auto 40px;
  `}
`

const RE2020AvantagesBBC = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Les avantages du bâtiment BBC pour un investissement immobilier
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Investir dans un programme BBC/RT 2012 comporte de nombreux avantages pour l’investisseur.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      La demande locative est relativement forte pour ce type de logement. De plus, pour pouvoir bénéficier des réductions d’impôt propres à la loi Pinel et Censi-Bouvard, le respect du label BBC est l’une des conditions demandées. Les investisseurs bénéficient donc d’une défiscalisation allant de 12 à 21% avec la loi Pinel et de 11% avec Censi-Bouvard.
    </HomeSectionDescription>
    <FormulaContainer>
      <HomeSectionDescription>
        Comme précédemment évoqué,<strong> investir dans un bâtiment neuf aux normes BBC et RT 2012</strong> permet également de profiter d’un potentiel de plus-value.
      </HomeSectionDescription>
    </FormulaContainer>
    <HomeSectionDescription>
      Enfin, plusieurs aides de l’État permettent aux investisseurs de profiter d’un petit coup de pouce pour pouvoir financer l’achat de leur bien. L’objectif de l’État est de pouvoir favoriser l’acquisition de ce type de logements pour pouvoir baisser les émissions de gaz à effet de serre au niveau national.
    </HomeSectionDescription>
    <StyledButtonContainer>
      <StyledButton
        link='/programmes-immobiliers-neufs'
        title='Voir les programmes immobiliers neufs'
        background='brightOrange' />
    </StyledButtonContainer>
  </Container>
)

export default RE2020AvantagesBBC
