import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import RT2012 from '../../molecules/RT2012'
import TopSection from '../../molecules/TopSection'
import RT2012Image from '../../molecules/RT2012Image'

const StyledRT2012Image = styled(RT2012Image)`
  z-index: -1;
  display: none;
  
  ${media.greaterThan('xl')`
    display: block;
    height: 672px; 
    top: 164px;
  `}
`

const RT2012TopSection = () => (
  <TopSection component={StyledRT2012Image}>
    <RT2012 />
  </TopSection>
)

export default RT2012TopSection
