import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0 120px ;
  `}
`

const RT2012Def = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Norme BBC/RT 2012, définition et prérequis
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La norme BBC/RT 2012 contraint les constructeurs à prévoir des bâtiments à basse consommation d’énergie. Pour pouvoir bénéficier du label BBC, il est donc nécessaire de respecter le cahier des charges.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Celui-ci comprend divers points, tous axés vers la consommation d’énergie et l’isolation thermique des bâtiments. La norme BBC/RT 2012 a pour objectif de réduire les émissions de gaz à effet de serre, ainsi que d’alléger la facture de consommation d’énergie des propriétaires et/ou locataires.
    </HomeSectionDescription>
  </Container>
)

export default RT2012Def
