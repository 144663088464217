import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RT2012Rentabilite = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-outro.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      maxText='450'
      fixed={true}
      right={true}
      imageData={placeholderImage}
      backgroundColor='white'>
    <HomeSectionTitle tag='h2'>
      La rentabilité d’un bien Neuf
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Même si les logements neufs présentent parfois un prix plus élevé que dans l’ancien, ceux-ci s’avèrent beaucoup plus rentables, puisque la facture d’énergie est considérablement réduite.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En effet, les logements BBC permettent d’effectuer de très grandes économies de chauffage, qu’il soit électrique ou au gaz. En outre, des aides au financement existent pour ce type de bâtiments : prêt à taux zéro, réduction de la TVA sur la main d’œuvre et certains matériaux, …
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default RT2012Rentabilite
