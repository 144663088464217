import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import ExpoIcon from '../../../assets/expo.inline.svg'
import HeaterIcon from '../../../assets/heater.inline.svg'
import IsolationIcon from '../../../assets/isolation.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import RenewableEnergyIcon from '../../../assets/renewable-energy.inline.svg'
import TechnicalEquipmentIcon from '../../../assets/technical-equipment.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 26px;

  ${media.lessThan('md')`
    flex-direction: column;
  `}

  ${media.greaterThan('sm')`
    gap: 16px;
  `}

  & > svg {
    height: 60px;

    ${media.greaterThan('sm')`
      height: 80px;
      width: 80px;
    `}
  }

  ${media.greaterThan('sm')`
    gap: 16px;
  `}
`

const StyledLineContainer = styled(LineContainer)`
  margin-top: 40px;
`

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  align-items: center;

  & > svg {
    ${media.lessThan('sm')`
      width: 60px;
      height: 60px;
    `}
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lessThan('md')`
    align-items: center;
    justify-content: center;
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  ${media.lessThan('md')`
    text-align: center;
  `}
`

const StyledHomeSectionTitle = styled(HomeSectionTitle)`
  font-size:22px;
`

const RT2012Points = () => (
  <HomeContainer max={1000}>
    <HomeSectionTitle>
      Les points à respecter par les constructeurs :
    </HomeSectionTitle>
    <StyledLineContainer>
      <ImageContainer>
        <RenewableEnergyIcon />
      </ImageContainer>
      <Container>
        <StyledHomeSectionTitle>
          Énergies renouvelables
        </StyledHomeSectionTitle>
        <StyledHomeSectionDescription>
          installation de systèmes permettant au bâtiment d’exploiter les énergies renouvelables, à titre facultatif car cela représente un faible potentiel d’économies d’énergie.
        </StyledHomeSectionDescription>
      </Container>
    </StyledLineContainer>
    <LineContainer>
      <ImageContainer>
        <HeaterIcon />
      </ImageContainer>
      <Container>
        <StyledHomeSectionTitle>
          Chauffage
        </StyledHomeSectionTitle>
        <StyledHomeSectionDescription>
          le choix du type d’énergie utilisée pour le chauffage doit se faire de manière réfléchie, en prenant en compte les besoins des bâtiments, la surface des logements, l’exposition du bâtiment.
        </StyledHomeSectionDescription>
      </Container>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <IsolationIcon />
      </ImageContainer>
      <Container>
        <StyledHomeSectionTitle>
          Isolation thermique
        </StyledHomeSectionTitle>
        <StyledHomeSectionDescription>
          la nouvelle construction doit avoir une isolation thermique optimale, qui permet de garder la chaleur en hiver et inversement qui permet au bâtiment de conserver de la fraîcheur pendant les fortes chaleurs.
        </StyledHomeSectionDescription>
      </Container>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <TechnicalEquipmentIcon />
      </ImageContainer>
      <Container>
        <StyledHomeSectionTitle>
          Équipements techniques
        </StyledHomeSectionTitle>
        <StyledHomeSectionDescription>
          les équipements tels que les radiateurs, les lumières, chaudières, pompes à chaleur, doivent être performants énergétiquement. Ils doivent consommer peu, mais permettre un bon rendement énergétique.
        </StyledHomeSectionDescription>
      </Container>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <ExpoIcon />
      </ImageContainer>
      <Container>
        <StyledHomeSectionTitle>
          Conception bioclimatique
        </StyledHomeSectionTitle>
        <StyledHomeSectionDescription>
          les nouvelles constructions doivent toujours être orientées de façon à ce que toutes les faces puissent profiter au maximum de la lumière naturelle et ainsi permettre aux habitants de faire des économies.
        </StyledHomeSectionDescription>
      </Container>
    </LineContainer>
  </HomeContainer>
)

export default RT2012Points
