import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 690px;
  margin: auto;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 545px;
    margin-right: 50%;
  `}
  
  ${media.greaterThan('xxl')`
    margin-right: 55%;
  `}
`

const RT2012 = () => (
  <>
    <StyledContainer>
      <Header>
        Norme BBC/RT 2012 :<br/>ce qu’il faut savoir
      </Header>
      <Subtitle>
        Les nouvelles constructions sont soumises à de nombreuses normes. La norme BBC/RT 2012 a pour vocation de limiter la consommation d’énergie des bâtiments neufs.
      </Subtitle>
      <Description>
        Cette norme concerne la plupart des programmes immobiliers neufs aujourd’hui. Au-delà de l’aspect basse consommation, les bâtiments soumis à la RT 2012 doivent également respecter d’autres critères. Folhomee revient plus précisément sur cette réglementation.
      </Description>
    </StyledContainer>
  </>
)

export default RT2012
