import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '420px'
  }
}

const RT2012Specifications = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RT2012/RT2012-specifications.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      small={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle>
        Le cahier des charges pour l’obtention du label BBC
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Afin de bénéficier du label BBC propre au respect de la norme RT 2012, les constructeurs doivent respecter un certain nombre d’éléments figurant dans le cahier des charges.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Les points de vigilance concernent principalement l’exposition au soleil du bâtiment, son isolation et le type d’énergie utilisée pour le chauffage.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RT2012Specifications
