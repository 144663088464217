import React from 'react'

import PictoEnergy from '../../../assets/energy.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import PictoNewBuilding from '../../../assets/new.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import PictoIsolationWater from '../../../assets/isolation-water.inline.svg'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoNewBuilding,
  title: 'Favoriser une évolution technologique et industrielle de tous les bâtiments et de leurs équipements'
}, {
  icon: PictoEnergy,
  title: 'Assurer un niveau de qualité énergétique optimal, peu importe le choix de l’énergie'
}, {
  icon: PictoIsolationWater,
  title: 'Permettre un équilibre économique entre l’énergie utilisée pour le chauffage et la production d’eau chaude sanitaire.'
}]

const RT2012EfficaciteEnerg = () => (
  <HomeContainer max={900}>
    <HomeSectionTitle>
      Les promoteurs et l’efficacité énergétique
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Les promoteurs doivent donc faire attention à l’efficacité énergétique du bâtiment neuf pour que celui-ci soit pauvre en consommation d’énergie et surtout respecte le seuil de chaleur confortable en été.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      L’objectif de la norme BBC/RT 2012 est également de :
    </HomeSectionDescription>
    <PictoColContainer details={DETAILS_COL}/>
    <HomeSectionDescription>
      Jusqu’à aujourd’hui, <strong>toutes les nouvelles constructions sont soumises à la RT 2012.</strong> À partir de 2022, les nouveaux bâtiments seront soumis à la norme RE 2020.
    </HomeSectionDescription>
  </HomeContainer>
)

export default RT2012EfficaciteEnerg
