import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '400'
  }
}

const RT2012Avantages = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RT2012/RT2012-avantages.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h3'>
        Les avantages des bâtiments BBC/RT 2012
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Premièrement, les bâtiments BBC/RT 2012 sont des constructions récentes, avec une architecture moderne et des équipements neufs.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Investir dans un bien neuf permet donc d’avoir un logement avec de la valeur, pouvant représenter une plus-value significative en cas de revente.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RT2012Avantages
