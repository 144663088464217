import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import RT2012Def from '../components/organisms/RT2012Def'
import SiteSection from '../components/atoms/SiteSection'
import RT2012Norme from '../components/organisms/RT2012Norme'
import RT2012Points from '../components/organisms/RT2012Points'
import RT2012Avantages from '../components/organisms/RT2012Avantages'
import RT2012TopSection from '../components/organisms/RT2012TopSection'
import RT2012Rentabilite from '../components/organisms/RT2012Rentabilite'
import RT2012AvantagesBBC from '../components/organisms/RT2012AvantagesBBC'
import RT2012Specifications from '../components/organisms/RT2012Specifications'
import RT2012EfficaciteEnerg from '../components/organisms/RT2012EfficaciteEnerg'

const TITLE = 'Norme BBC/RT 2012 : ce qu’il faut savoir'
const DESCRIPTION = 'Les points essentiels concernant la norme BBC/RT 2012 pour les bâtiments neufs.'

const Rt2012 = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <RT2012TopSection />
    <SiteSection id='RT2012-caracteristique' $first={true}>
      <RT2012Def />
    </SiteSection>
    <SiteSection id='RT2012-norme'>
      <RT2012Norme />
    </SiteSection>
    <SiteSection id='RT2012-efficacite-energetique'>
      <RT2012EfficaciteEnerg />
    </SiteSection>
    <SiteSection id='RT2012-specifications'>
      <RT2012Specifications />
    </SiteSection>
    <SiteSection id='RT2012-points-a-respecter'>
      <RT2012Points />
    </SiteSection>
    <SiteSection id='RT2012-avantages'>
      <RT2012Avantages />
    </SiteSection>
    <SiteSection id='RT2012-efficacite-energetique'>
      <RT2012Rentabilite />
    </SiteSection>
    <SiteSection id='RT2012-avantages-bbc'>
      <RT2012AvantagesBBC />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

Rt2012.propTypes = {
  location: PropTypes.object.isRequired
}

export default Rt2012
