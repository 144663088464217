import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '360'
  }
}

const StyledImageSection = styled(ImageSection)`
  ${media.lessThan('xl')`
    flex-direction: column;
  `}
`

const RT2012Norme = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RT2012/RT2012-norme.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <StyledImageSection
    size={size}
    reverse={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'
    maxText='440'>
    <HomeSectionTitle tag='h3'>
      La norme BBC/RT 2012, qu’est-ce que c’est exactement ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La norme BBC/RT 2012 concerne les nouvelles constructions érigées à partir de 2012.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Concrètement, les constructeurs doivent suivre tout un cahier des charges afin que les nouveaux bâtiments aient une consommation d’énergie <strong>inférieure à 50 kWhEP/m2 par an.</strong>
    </HomeSectionDescription>
  </StyledImageSection>
  )
}

export default RT2012Norme
